.link-underline {
    border-bottom-width: 0;
    background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
    background-size: 0 3px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size .5s ease-in-out;
}

.link-underline-teal {
    background-image: linear-gradient(transparent, transparent), linear-gradient(#0B7167, #0B7167)
}

.link-underline-sky {
    background-image: linear-gradient(transparent, transparent), linear-gradient(#86C7D6, #86C7D6)
}

.link-underline:hover {
    background-size: 100% 3px;
    background-position: 0 100%
}

.link-underline-teal-perm {
    background-size: 100% 3px;
    background-position: 0 100%;
    background-image: linear-gradient(transparent, transparent), linear-gradient(#0B7167, #0B7167);
    background-repeat: no-repeat;
}

.link-underline-sky-perm {
    background-size: 100% 3px;
    background-position: 0 100%;
    background-image: linear-gradient(transparent, transparent), linear-gradient(#86C7D6, #86C7D6);
    background-repeat: no-repeat;
}